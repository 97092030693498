@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

.row {
    &.expand {
        max-width: none;
    }
}


input {
    &[type="submit"] {
        @include button();
        //-webkit-appearance: none;
    }
}

body {
    background-attachment: fixed;
    
    @include breakpoint(medium) {
        padding-right: $grid-column-gutter / 2;
    }
}

#logo {
    display: block;
    background: url(../img/logo.png) no-repeat top right;
    width: 100%;
    height: 158px;
}

#container {
    background-color: $vovwoon-bg2;
    
    @include breakpoint(medium) {
        border: rem-calc(3) solid $white;
        margin-bottom: rem-calc(50);
    }
    
    > .content {
        > .title {
            background: $primary-color;
            padding: rem-calc(5 10 0 0);
            
            a {
                color: $white;
                text-decoration: none;
                font-weight: bold;
            }
            
            h1 {
                color: $vovwoon-bg2;
            }
            
            ul {
                padding: 0;
                margin: 0;
            }
            
            li {
                list-style: none;
                float: left;
                padding: rem-calc(0 10);
                border-right: rem-calc(2) solid $vovwoon-bg2;
                
                &:last-child {
                    border-right: none;
                }
            }
            
            a, h1 {
                text-transform: uppercase;
                font-size: rem-calc(24);
                line-height: rem-calc(24);
            }
        
            @include breakpoint(medium) {
                border-bottom: rem-calc(3) solid $white;
            }
        }
        
        > .spacer {
            padding: $grid-column-gutter;
            
            @include breakpoint(small-only) {
                padding-right: 0;
            }
        }
    }
}

.title-bar {
    background-color: $primary-color;
}

body {
    @include breakpoint(medium) {
        padding-top: rem-calc(20);
    }
    background-repeat: no-repeat;
    background-size: cover;
}

.menu {
    li {
        border-radius: rem-calc(0 0 15 0);
        
        background: $primary-color;
        margin: rem-calc(10) 0;
        font-weight: bold;
        border: rem-calc(3) solid $white;
        font-family: $vovwoon-special-font;
        font-size: rem-calc(18);
        
        @include breakpoint(small-only) {
            margin-left: rem-calc(10);
            margin-right: rem-calc(-20);
        }
        
        a {
            text-decoration: none;
            padding: rem-calc(8);
        }
        
        &.dark {
            background: $vovwoon-bg2;
        }
    }
    
    @include breakpoint(medium) {
        margin: rem-calc(25 20 50 0)
    }
}

footer {
    position: fixed;
    bottom: 0;
    padding: 0 rem-calc(5);
    left: 0;
    right: 0;
    background-color: $primary-color;
    font-weight: bold;
    font-family: $vovwoon-special-font;
    
    a {
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;
        }
    }
}

table {
  font-size: 0.8em;
  width: 100%;

  .button {
    font-size: rem-calc(14);
    padding: rem-calc(4 12);
    margin-bottom: 0;
  }

  .button-group {
    float: right;
    font-size: 1em;
    margin-bottom: 0;
  }
  
  input, select {
      margin-bottom: 0 !important;
  }
}

.button {
    &.square {
        width: 2.2em;
        height: 2.2em;
        padding: 0;
        text-align: center;
        line-height: 2.2em;
    }
}

.callout {
    color: $callout-font-color;
}